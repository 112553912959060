<template>
  <div>
    <div class="analysis-kibmd__tabs">
      <m-tabs v-model="value" :slider-size="2" :with-route="false">
        <v-tab key="Organization">Медицинские организации</v-tab>
        <v-tab key="Indicator">Показатели качества</v-tab>
      </m-tabs>

      <v-window :value="value">
        <v-window-item>
          <more1
            :data="data"
            :labels="labels"
            :tfomsParams="tfomsParams"
          ></more1>
        </v-window-item>
        <v-window-item :labels="labels">
          <more2 :data="data" :labels="labels"></more2>
        </v-window-item>
      </v-window>
    </div>
  </div>
</template>
<script>
import mTabs from "@/components/base/tabs/mTabs";

export default {
  components: {
    mTabs,
    more1: () => import("../tabs/more/tab1.vue"),
    more2: () => import("../tabs/more/tab2.vue"),
  },
  provide() {
    const tfomsParams = {};

    Object.defineProperty(tfomsParams, "value", {
      enumerable: true,
      get: () => this.tfomsParams,
    });
    return {
      tfomsParams: tfomsParams,
    };
  },
  props: {
    tfomsParams: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      value: 0,
      data: null,
      labels: [],
    };
  },
  // Сборс некоторых глобальных параметров
  watch: {
    value(val) {
      this.$emit("updatedetailParams", {
        reasonId: null,
      });
    },
  },
  methods: {
    setData(val) {
      this.data = val;
    },
    setLabels(val) {
      this.labels = val;
    },
  },
};
</script>
<style lang="scss"></style>
